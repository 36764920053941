import $, { error } from 'jquery';
import ons from 'onsenui';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import { DEBUG, LANG, showToast, onPullHookChangeState, getParameterByName, getCookie, setCookie } from  '../../src/js/script.js';

import { AuthClient } from "@dfinity/auth-client";
import { Principal } from "@dfinity/principal";
import { createActor, canisterId } from "../../icp-buyer-seller-contract-backend"

const { v4: uuidv4 } = require('uuid');
import axios from 'axios';
import i18next from 'i18next';

const APP_URL = process.env.APP_URL;
const BEARER_TOKEN = "EAAHyZCjZAMC7oBAGjzmDxqVxJq77BrhV7TIqLMLZCLV7UaB69OcD2nssv9y9bpCmMA0SMFkF91zF93PnrhPInAQeWSkbSmyEr8ZC8fdNbQ5Fxllu3ZAzKsMqjMv3MaXRSwbf7sklghONHSKzu4bJ9unlSnfBZBhZCqUKyUyTUmy1IhJzxFFyezccNzU0bBkm5qLA4gYC4DXc2y0qdFjGSTD";

const CANISTER_ID = (true && process.env.NODE_ENV=="production") ? "myail-eyaaa-aaaag-alluq-cai" : "bkyz2-fmaaa-aaaaa-qaaaq-cai";
const DFX_NETWORK = (true && process.env.NODE_ENV=="production") ? "https://icp-api.io" : "http://127.0.0.1:4943";
  
$(document).ready(async function() {
    
  ons.ready(async function() {
    	if(DEBUG) console.log("Onsen ready");
    	ons.disableDeviceBackButtonHandler();

      const authClient = await AuthClient.create();
      const isAuthenticated = await authClient.isAuthenticated();
      
  		if(isAuthenticated) {
        const templateName = "footerMenuTemplate";
        document.querySelector('#myNavigator').pushPage(templateName);
        window.history.pushState({page: templateName}, templateName);
  		} else {
        const templateName = "loginTemplate";
        document.querySelector('#myNavigator').pushPage(templateName);
        window.history.pushState({page: templateName}, templateName);
		}	  
  });

});

// On page init
document.addEventListener('init', function(event) {
	var page = event.target;
	if(page.id=="") return;
	if(DEBUG) console.log("Init event for page: "+page.id);	
	switch(page.id) {
		case 'loginPage':
			initializeLoginPage(page);
			break;
    case 'footerMenuPage':
      initializeFooterMenuPage(page);
      break;
		case 'accountPage':
			initializeAccountPage(page);
			break;
    case 'editContractPage':
        initializeEditContractPage(page);
        break;
    case 'viewContractPage':
			initializeViewContractPage(page);
			break;
    case 'shareContractPage':
      initializeShareContractPage(page);
      break;
    case 'addContractPage':
      initializeAddContractPage(page);
      break;
	}		
});
// On page show
document.addEventListener('show', function(event) {
  var page = event.target;
	if(page.id=="") return;
	if(DEBUG) console.log("Showing page: "+page.id);
	var pageElement = $("#"+page.id).localize();
	switch(page.id) {
		case 'loginPage':
			break;
	}
});
// On page hide
document.addEventListener('hide', function(event) {
	var page = event.target;
	if(page.id=="") return;
	if(DEBUG) console.log("Hiding page: "+page.id);
	switch(page.id) {
		case 'loginPage':
			break;
	}
});
// On page destroy
document.addEventListener('destroy', function(event) {
	var page = event.target;
	if(page.id=="") return;
	if(DEBUG) console.log("Destroying page: "+page.id);
	switch(page.id) {
		case 'loginPage':
			break;
	}
});

// footerMenuPage
async function initializeFooterMenuPage(page) {
	if(DEBUG) console.log("Initializing footerMenuPage...");
	var pageElement = $("#"+page.id).localize();
  new ResizeObserver(function(){
    //$("#accountPage").find(".page__content").height(window.innerHeight - 56);
  }).observe(pageElement.find(".page__content")[0]);

  initializeNavdrawer();
  
  var flag = (LANG=="en") ? "gb" : LANG;
  $("[data-target='#languageModal']").find(".flag").removeClass(function(index, className) {
    return (className.match(/\bflag-icon-\S+/g) || []).join(' ');
  }).addClass("flag-icon-"+flag);

  var uid = getParameterByName(window.location.href, "sharedContract");
  if(typeof uid!=='undefined' && uid!==null) {
    const templateName = "addContractTemplate";
    ons.createDialog(templateName, { append: true }).then(function(dialog) {
        $(dialog).attr("data-uid", uid);
        dialog.show();
        window.history.pushState({page: templateName}, templateName);
    });
  }
}
function initializeNavdrawer() {
  if(DEBUG) console.log("Initializing navdrawer...");

  $("#navdrawer").find('#logoutBtn').off("click").on("click", async function() {
    if(DEBUG) console.log("Click on logoutBtn...");
    const authClient = await AuthClient.create();
    await authClient.logout();
    window.location.href = '/';
  });
}
// loginPage
async function initializeLoginPage(page) {
	if(DEBUG) console.log("Initializing login page...");
	var pageElement = $("#"+page.id).localize();
	
	const authClient = await AuthClient.create();
   
  pageElement.find('#loginBtn').on("click", async function() {
    if(DEBUG) console.log("Click on loginBtn...");
    authClient.login({
      identityProvider: "https://identity.ic0.app/#authorize",
        onSuccess: async function() {
          console.log("Login callback");
          window.location.reload();
        }
    });
  });
}
// accountPage
async function initializeAccountPage(page) {
	if(DEBUG) console.log("Initializing accountPage...");
	var pageElement = $("#"+page.id).localize();
  getAccount();
  var pullHook = page.querySelector('[id=pullHook]');
  pullHook.addEventListener('changestate', onPullHookChangeState);
  pullHook.onAction = function(done) {
    getAccount(done);
  };
  pageElement.find("#addContractBtn").off("click").on("click", function(){
    console.log("Click on addContractCardBtn...");
    pageElement.find("#createContractCard").slideToggle();
  });

  pageElement.find("#createContractBtn").off("click").on("click", function(e){
    e.preventDefault();
    console.log("Click on createContractBtn...");
    createContract();
  });

  pageElement.find('#editAccountBtn').off("click").on("click", async function() {
    console.log("Click on editAccountBtn...");
    showToast("Coming soon...");
  });
	
}
async function getAccount(done) {
  if(DEBUG) console.log("Getting account...");
	var pageElement = $("#accountPage");
  var content = pageElement.find("#content");
  var progressCircular = pageElement.find("#progressCircular");
  content.hide();
  progressCircular.show();

	const authClient = await AuthClient.create();
  const isAuthenticated = await authClient.isAuthenticated();
  const principal = authClient.getIdentity().getPrincipal().toText();
  setCookie("principal", principal, 365);

  pageElement.find("#accountCard").find("#principal").text(principal);

  getContracts(principal).then((contracts) => {
    if(contracts!==null && contracts.length>0) {
      content.find("#contractList").empty();
      contracts.forEach(contract => {
        var html = "";
        html += "<div class='list-group-item list-group-item-action contract' uid='"+contract.uid+"'>";
        html += "<div class='info'>"
        html += "<div class='name'>"+contract.name+"</div>";
        if(contract.sellerId==principal) {
          html += "<div class='role'>"+i18next.t("accountPage.Seller")+"</div>";
        } else if(contract.buyerId==principal) {
          html += "<div class='role'>"+i18next.t("accountPage.Buyer")+"</div>";
        }
        html += "</div>";
        html += "<div class='btns'>";
        html += "<button class='btn viewBtn'><span class='material-symbols-outlined icon'>visibility</span></button>";
        html += "<button class='btn editBtn'><span class='material-symbols-outlined icon'>edit</span></button>";
        html += "<button class='btn deleteBtn'><span class='material-symbols-outlined icon'>delete</span></button>";
        html += "<button class='btn shareBtn'><span class='material-symbols-outlined icon'>share</span></button>";
        html += "</div>";
        html += "</div>";
        $("#contractList").append(html);
      });
      content.find("#noContracts").hide();
      content.find("#contractList").show();
      content.find("#contractList").find(".contract").off("click").on("click", function(e){
        if(DEBUG) console.log("Click on contract...");
        const uid = $(this).attr("uid");
        var templateName = "";
        if($(e.target).closest(".btn").hasClass("viewBtn")) {
          templateName = "viewContractTemplate";
        } else if($(e.target).closest(".btn").hasClass("editBtn")) {
          templateName = "editContractTemplate";
        } else if($(e.target).closest(".btn").hasClass("deleteBtn")) {
          $(this).prop("disabled", true);
          deleteContract(contract).then((contract) => {
            getAccount();
          }).catch((error) => {
            showToast("Error deleting contract on database.");
            $(this).prop("disabled", false);
          });
        } else if($(e.target).closest(".btn").hasClass("shareBtn")) {
          templateName = "shareContractTemplate";
        } else {
          templateName = "viewContractTemplate";
        }
        if(templateName!=="") {
          document.querySelector('#myNavigator').pushPage(templateName, {data: {uid: uid}});
          window.history.pushState({page: templateName}, templateName);
        }
      });
    } else {
      content.find("#noContracts").show();
      content.find("#contractList").hide();
    }
    content.show();
    progressCircular.hide();
    if(done) done();
  }).catch((error) => {
    if(DEBUG) console.error('Error:', error);
    if(done) done();
  });

}
async function createContract() {
  if(DEBUG) console.log("Creating contract...");
	var pageElement = $("#accountPage");
  var form = pageElement.find("#createContractForm");
  if(form[0].checkValidity() === false) {
    form.addClass('was-validated');
    return;
  }
  const time = Math.floor(Date.now()/1000);
  const principal = getCookie("principal");
  const contract = {
    uid: uuidv4(),
    name: form.find("input[name=name]").val(),
    buyerId: (form.find("select[name=role]").val()=="buyer") ? principal : null,
    sellerId: (form.find("select[name=role]").val()=="seller") ? principal : null,
    created: time,
    createdBy: principal,
    updated: time,
    updatedBy: principal
  };

  postContract(contract).then((contract) => {
    pageElement.find("#addContractBtn").trigger("click");
    getAccount();
  }).catch((error) => {
    showToast("Error saving contract on database.");
  });

}
// editContractPage
function initializeEditContractPage(page) {
	if(DEBUG) console.log("Initializing editContractPage...");
	var pageElement = $("#"+page.id).localize();
  getEditContract(page.data.uid);
  var pullHook = page.querySelector('[id=pullHook]');
  pullHook.addEventListener('changestate', onPullHookChangeState);
  pullHook.onAction = function(done) {
    getEditContract(page.data.uid, done);
  };
  pageElement.find("#backBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on backBtn...");
    document.querySelector('#myNavigator').popPage();
  });
}
function getEditContract(uid, done) {
	if(DEBUG) console.log("Getting editContract...");
  var pageElement = $("#editContractPage");
  var content = pageElement.find("#content");
  var progressCircular = pageElement.find("#progressCircular");
  content.hide();
  progressCircular.show();
  
  getContract(uid).then((contract) => {
    const form = pageElement.find("#editContractForm");    
    form.find("select[name=shippingTermId]").find("option").hide();
    form.find("select[name=shippingTermCatalogId]").off("change").on("change", function(e){
        e.preventDefault();
        if(DEBUG) console.log("Change on shippingTermCatalogId...");
        const shippingTermCatalogId = form.find("select[name=shippingTermCatalogId]").val();
        form.find("select[name=shippingTermId]").val("");
        form.find("select[name=shippingTermId]").find("option").hide();
        form.find("select[name=shippingTermId]").find("option[shippingTermCatalogId='"+shippingTermCatalogId+"']").show();        
    });
    form.find("#addItemBtn").off("click").on("click", function(e){
        e.preventDefault();
        if(DEBUG) console.log("Click on addItemBtn...");
        var clone = form.find(".itemRow:first-child").clone();
        clone.find("input").val("");
        clone.appendTo("#itemRows");
    });
    form.off("keyup").on("keyup", function(){
        if(DEBUG) console.log("Change on contractForm...");
        var showProductExportAlert = false;
        form.find("input[name='itemCode[]']").each(function(){
            if($(this).val()=="XX" && form.find("select[name=buyerCountry]").val()=="IT") {
                showProductExportAlert = true;
            }
        });
        if(showProductExportAlert) {
            form.find("#productExportAlert").show();
        } else {
            form.find("#productExportAlert").hide();
        }
    });
    form.find("#saveBtn").off("click").on("click", async function(e) {
      e.preventDefault();
      if(DEBUG) console.log("Click on saveBtn...");
      contract.data = JSON.stringify(formToJson(form[0]));
      postContract(contract).then((contract) => {
        const templateName = "viewContractTemplate";
        document.querySelector('#myNavigator').replacePage(templateName, {data: {uid: uid}});
        window.history.replaceState({page: templateName}, templateName);
      });
   });
   content.show();
   progressCircular.hide();
   if(done) done();
  }).catch((error) => {
    showToast("Error");
    if(done) done();
  });
}
// viewContractPage
function initializeViewContractPage(page) {
	if(DEBUG) console.log("Initializing contractPage...");
	var pageElement = $("#"+page.id).localize();
  getViewContract(page.data.uid);
  var pullHook = page.querySelector('[id=pullHook]');
  pullHook.addEventListener('changestate', onPullHookChangeState);
  pullHook.onAction = function(done) {
    getViewContract(page.data.uid, done);
  };
  pageElement.find("#backBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on backBtn...");
    document.querySelector('#myNavigator').popPage();
  });
}
function getViewContract(uid, done) {
	if(DEBUG) console.log("Getting contract...");
  var pageElement = $("#viewContractPage");
  var content = pageElement.find("#content");
  var progressCircular = pageElement.find("#progressCircular");
  content.hide();
  progressCircular.show();
  getContract(uid).then((contract) => {
      pageElement.find("#backToolbar").find(".center").removeAttr("data-i18n").text(contract.name);

      pageElement.find(".statusDiv").hide();
      pageElement.find(".step").removeClass("done");
      if(contract.completed!==null) {
        pageElement.find(".statusDiv#completed").show();
        pageElement.find(".step#stored").addClass("done");
        pageElement.find(".step#signed").addClass("done");
        pageElement.find(".step#shipped").addClass("done");
        pageElement.find(".step#completed").addClass("done");
      } else if(contract.delivered!==null) {
        pageElement.find(".statusDiv#delivered").show();
        pageElement.find(".step#stored").addClass("done");
        pageElement.find(".step#signed").addClass("done");
        pageElement.find(".step#shipped").addClass("done");
        pageElement.find(".step#delivered").addClass("done").addClass("active");
      } else if(contract.shipped!==null) {
        pageElement.find(".statusDiv#shipped").show();
        pageElement.find(".step#stored").addClass("done");
        pageElement.find(".step#signed").addClass("done");
        pageElement.find(".step#shipped").addClass("done").addClass("active");
      } else if(contract.signed!==null) {
        pageElement.find(".statusDiv#signed").show();
        pageElement.find(".step#stored").addClass("done");
        pageElement.find(".step#signed").addClass("done").addClass("active");
      } else if(contract.stored!==null) {
        pageElement.find(".statusDiv#stored").show();
        pageElement.find(".step#stored").addClass("done").addClass("active");
        if(contract.sellerId==getCookie("principal") && contract.sellerSignature!==null) {
          pageElement.find("#signContractBtn").prop("disabled", true);
        } else if(contract.buyerId==getCookie("principal") && contract.buyerSignature!==null) {
          pageElement.find("#signContractBtn").prop("disabled", true);
        }
        pageElement.find("#signContractBtn").off("click").on("click", function(){
          if(DEBUG) console.log("Click on signContractBtn...");
          signContract(uid);
        });
      } else {
        pageElement.find(".statusDiv#draft").show();

        pageElement.find("#storeContractBtn").off("click").on("click", function(){
          if(DEBUG) console.log("Click on storeContractBtn...");
          storeContract(uid);
        });
      }

      pageElement.find("#uid").text(contract.uid);
      pageElement.find("#name").text(contract.name);
      pageElement.find("#model").text("ICC");
      if(contract.sellerId!==null) {
        pageElement.find("#sellerId").text(contract.sellerId);
      }
      if(contract.buyerId!==null) {
        pageElement.find("#buyerId").text(contract.buyerId);
      }
      if(contract.sellerSignature!==null) {
        pageElement.find("#sellerSignature").text(moment(contract.sellerSignature*1000).format('llll'));
      }
      if(contract.buyerSignature!==null) {
        pageElement.find("#buyerSignature").text(moment(contract.buyerSignature*1000).format('llll'));
      }

      pageElement.find("#openPdfBtn").off("click").on("click", function(){
        if(DEBUG) console.log("Click on openPdfBtn...");
        window.open("https://pdfobject.com/pdf/sample.pdf", "_blank");
      });
      content.show();
      progressCircular.hide();
      if(done) done();
  }).catch((error) => {
    if(DEBUG) console.error('Error:', error);
    showToast("Error");
    if(done) done();
  });
}
async function storeContract(uid) {
  if(DEBUG) console.log("Storing contract...");
  var pageElement = $("#viewContractPage");
  var btn = pageElement.find("#storeContractBtn");
  btn.prop("disabled", true);

  getContract(uid).then(async (contract) => {

    if(contract.sellerId==null) {
      showToast("Seller not defined.");
      btn.prop("disabled", false);
      return;
    }

    if(contract.buyerId==null) {
      showToast("Buyer not defined.");
      btn.prop("disabled", false);
      return;
    }

    const CONTRACT_JSON = contract.data;
    const SELLER_PRINCIPAL = contract.sellerId;
    const BUYER_PRINCIPAL = contract.buyerId;
  
    const backendCanister  = createActor(CANISTER_ID, { agentOptions:{host: DFX_NETWORK}}); //, identity: }});
    const result = await backendCanister
      .create_contract(CONTRACT_JSON, Principal.fromText(BUYER_PRINCIPAL), Principal.fromText(SELLER_PRINCIPAL))
      .catch(function(){
        console.log("catch");
      });
    console.log(result);
  
    if(parseInt(result)>0){
      contract.id = parseInt(result);
      contract.stored = Math.floor(Date.now()/1000);
      postContract(contract).then((contract) => {
        getViewContract(uid);
      });
    } else {
      showToast("Error storing contract on ICP chain.");
      btn.prop("disabled", false);
    }
  }).catch((error) => {
    console.error('Error:', error);
    showToast("Error");
  });
}
async function signContract(uid) {
  if(DEBUG) console.log("Sign contract...");
  var pageElement = $("#editContractPage");
  var form = pageElement.find("#editContractForm");
  var btn = pageElement.find("#signContractBtn");
  btn.prop("disabled", true);

  const authClient = await AuthClient.create();
  const identity = await authClient.getIdentity();
  const principal = authClient.getIdentity().getPrincipal().toText();
  
  getContract(uid).then(async (contract) => {
    const backendCanister  = createActor(CANISTER_ID, { agentOptions:{host: DFX_NETWORK, identity: identity} });
    const result = await backendCanister
      .sign_contract(contract.id)
      .then(function(){
        const time = Math.floor(Date.now()/1000);
        if(principal==contract.sellerId) {
          contract.sellerSignature = time;
        } else if(principal==contract.buyerId) {
          contract.buyerSignature = time;
        }
        if(contract.sellerSignature!==null && contract.buyerSignature!==null) {
          contract.signed = time;
        }
        postContract(contract).then((contract) => {
          getViewContract(uid);
        });
      })
      .catch(function(e){
        console.log("catch", e);
        showToast("Error signing contract on ICP chain.");
        btn.prop("disabled", false);
      });
    console.log(result);
  }).catch((error) => {
    console.error('Error:', error);
    showToast("Error");
  });
  
}
// shareContractPage
function initializeShareContractPage(page) {
	if(DEBUG) console.log("Initializing shareContractPage...");
	var pageElement = $("#"+page.id).localize();
  getShareContract(page.data.uid);
  var pullHook = page.querySelector('[id=pullHook]');
  pullHook.addEventListener('changestate', onPullHookChangeState);
  pullHook.onAction = function(done) {
    getShareContract(page.data.uid, done);
  };
  pageElement.find("#backBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on backBtn...");
    document.querySelector('#myNavigator').popPage();
  });
}
function getShareContract(uid, done) {
	if(DEBUG) console.log("Getting shareContract...");
  var pageElement = $("#shareContractPage");
  var content = pageElement.find("#content");
  var progressCircular = pageElement.find("#progressCircular");
  content.hide();
  progressCircular.show();

  const shareContractUrl = "https://app.tradeonchain.io?sharedContract="+uid;
  pageElement.find("#url").val(shareContractUrl);
  pageElement.find("#qrcode").find("img").attr("src", APP_URL+"/api/v1/qrcode?text="+encodeURI(shareContractUrl));
  
  const form = pageElement.find("#shareContractForm");
  form.find("#shareBtn").off("click").on("click", function(e){
		if(DEBUG) console.log("Click on shareBtn...");
		e.preventDefault();
		const shareMsg = form.find("#message").val();
		const shareUrl = form.find("#url").val();
		if(navigator.share) {
			navigator.share({
				text: shareMsg,
				url: shareUrl
			}).then(function(){
				if(DEBUG) console.log('Successful share');
			}).catch(function(error){
				if(DEBUG) console.error("Error sharing", error);
			});
		} else {
			navigator.clipboard.writeText(shareMsg+shareUrl);
			showToast("I dati per la condivisione sono stati copiati negli appunti, incollali dove preferisci.");
		}
	});
	pageElement.find("#copyBtn").off("click").on("click", function(e){
		e.preventDefault();
		if(DEBUG) console.log("Copy password to clipboard");
		/* Get the text field */
		var copyText = document.getElementById("url");  
		/* Select the text field */
		copyText.select();
		copyText.setSelectionRange(0, 99999); /*For mobile devices*/
		/* Copy the text inside the text field */
		document.execCommand("copy");
		pageElement.find("#url").tooltip('show');
		$(this).prop("disabled", true);
	});
  content.show();
  progressCircular.hide();
  if(done) done();
}
// addContractPage
function initializeAddContractPage(page) {
	if(DEBUG) console.log("Initializing addContractPage...");
	var pageElement = $("#"+page.id).localize();
  var dialogElement = pageElement.closest("ons-dialog");
  var uid = dialogElement.attr("data-uid");
  getAddContract(uid);
  pageElement.find("#backBtn, #cancelBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on backBtn...");
    if(dialogElement.length) {
      dialogElement[0].hide().then(function() {
        if(DEBUG) console.log("Removing opened dialog...");
        dialogOpened[0].remove();
      });
    }
  });
}
function getAddContract(uid, done) {
	if(DEBUG) console.log("Getting addContract...");
  var pageElement = $("#addContractPage");
  var content = pageElement.find("#content");
  content.hide();

  getContract(uid).then((contract) => {
    if(DEBUG) console.info('Success');
      content.show();
      if(done) done();
      pageElement.find("#name").text(contract.name);

      pageElement.find("#addBtn").off("click").on("click", function(){
        if(DEBUG) console.log("Click on addBtn...");
        const time = Math.floor(Date.now()/1000);
        const principal = getCookie("principal");
        if(contract.sellerId==null) {
          contract.sellerId = principal;
        } else if(contract.buyerId==null) {
          contract.buyerId = principal;
        } else {
          showToast("Error: buyer and seller are already assigned to this contract.");
          return;
        }
        contract.updatedBy = principal;
        contract.updated = time;
        addContract(contract);
      });
  }).catch((error) => {
    if(DEBUG) console.error('Error:', error);
    showToast("Error");
  });
}
function addContract(contract) {
	if(DEBUG) console.log("Adding contract...");
  var pageElement = $("#addContractPage");
  postContract(contract).then((contract) => {
    pageElement.find("#backBtn").trigger("click");
    getAccount();
  }).catch((error) => {
    if(DEBUG) console.error('Error:', error);
    showToast("Error");
  });
}



// DB API
function getContracts(principal) {
  return new Promise((resolve, reject) => { 
    const url = APP_URL+'/api/v1/contracts?principal='+principal;
    axios.get(url, {
    headers: {
      'Authorization': `Bearer ${BEARER_TOKEN}`,
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if(DEBUG) console.log('Response:', response.data);
    resolve(response.data.data);
  }).catch(error => {
    if(DEBUG) console.error('Error:', error);
    reject(error);
  }); 
});
}
function getContract(uid) {
  return new Promise((resolve, reject) => {
    const url = APP_URL+'/api/v1/contracts/'+uid;
    axios.get(url, {
    headers: {
      'Authorization': `Bearer ${BEARER_TOKEN}`,
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if(DEBUG) console.log('Response:', response.data);
    if(response.data!==null) {
      const contract = response.data;
      resolve(contract);
    } else {
      reject()
    }
  }).catch(error => {
    if(DEBUG) console.error('Error:', error);
    reject(error);
  }); 
});
}
function postContract(requestBody) {
  return new Promise((resolve, reject) => {
    const url = APP_URL+'/api/v1/contracts';
    axios.post(url, requestBody, {
      headers: {
        'Authorization': `Bearer ${BEARER_TOKEN}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(DEBUG) console.log('Response:', response.data);
      resolve(response);
    }).catch(error => {
      if(DEBUG) console.error('Error:', error);
      reject(error);
    });
});
} 
function deleteContract(uid) {
  return new Promise((resolve, reject) => {
    const url = APP_URL+'/api/v1/contracts/'+uid;
    axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${BEARER_TOKEN}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(DEBUG) console.log('Response:', response.data);
      if(response.data!==null) {
        const contract = response.data;
        resolve(contract);
      } else {
        reject()
      }
    }).catch(error => {
      if(DEBUG) console.error('Error:', error);
      reject(error);
    }); 
  });
}





function formToJson(form) {
  const formData = new FormData(form);
  const jsonObject = {};

  // Itera su tutti i campi del form
  formData.forEach((value, key) => {
    // Se il campo esiste già come chiave (es. checkbox con lo stesso name), crea un array
    if (jsonObject[key]) {
      if (!Array.isArray(jsonObject[key])) {
        jsonObject[key] = [jsonObject[key]];
      }
      jsonObject[key].push(value);
    } else {
      jsonObject[key] = value;
    }
  });

  return jsonObject;
}
function jsonToForm(json, form) {
  Object.keys(json).forEach(key => {
    const field = form.querySelector(`[name=${key}]`);

    // Verifica se il campo esiste nel form
    if (field) {
      field.value = json[key];
    }
  });
}